import React, { Component } from 'react';
import PropTypes from 'prop-types';
import URL from "url";
import axios from "axios";
import lodash from "lodash";
import { BrowserRouter, Switch, Route, Link } from 'react-router-dom';
import 'normalize.css';
import './css/app.scss';
import {MuiThemeProvider, createMuiTheme } from '@material-ui/core/styles';
import PageContainer from "./components/page_container";
import Footer from "./components/Footer";
import LandingComponent from "./components/landing_component";

class App extends Component {

  render() {
    return (
      <BrowserRouter>
        <Route exact path="/" render={() => {
          return(
            <>
              <LandingComponent />
            </>
          )
        }} />
        <Route exact path="/:page_address" render={(match) => {
          return(
            <PageContainer match={match.match.params.page_address}/>
          )
        }}/>
      </BrowserRouter>
    );
  }

}


App.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default App;
